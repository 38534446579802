<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">Alle studentene på UiA bør få tilbud om praksis i utdanningsløpet sitt.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              Praksis blir ofte nevnt som en måte å øke arbeidslivsrelevans i studier. Det er to grupper av praksis,
              lovpålagt og ikke-lovpålagt. De som har lovpålagt, er lærerutdanninger og helse- og sosialfagutdanninger.
              Andre studieretninger kan universitetet styre mer over selv.
            </p>
            <p>Spørsmålet handler om du ønsker at alle studenter, uavhengig av studieretning, skal ha tilbud om praksis,
              eller om praksis skal begrenset til de som har lovpålagt praksis.
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>